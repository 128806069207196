<template>
  <div>
    <v-flex class="d-flex ma-2">

      <v-card>
        <v-navigation-drawer
            permanent
            class="bottom-gradient"
            v-model="drawer"
            :mini-variant.sync="mini"
        >
          <v-text-field
              v-model="search2"
              append-icon="mdi-magnify"
              :label="$t('cari')"
              single-line
              hide-details
              dense
              class="mt-2 mx-2"
              v-if="! mini"
          ></v-text-field>

          <v-list height="1">
            <v-list-group
                v-for="nav_drawer in nav_drawers.filter(x=>x.visible &&
                (x.items.some( x => x.titleId.toLowerCase().includes(search2.toLowerCase()))) || x.titleId.toLowerCase().includes(search2.toLowerCase()))"
                :key="nav_drawer.titleId"
                no-action
                small
                v-model="nav_drawer.active"
            >
              <template v-slot:activator>
                <v-list-item-avatar size="28">
                  <v-img :src="nav_drawer.icon" class="v-size--x-small"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="body-2" v-text="nav_drawer.titleId"> </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="submenu in nav_drawer.items.filter(x => x.visible && x.titleId.toLowerCase().includes(search2.toLowerCase()) )"
                  :key="submenu.titleId"
                  link
                  @click="submenuClick(submenu.id)"
                  class="navbar_submenu_color" >

                <template>
                  <v-list-item-content>
                    <v-list-item-title class="body-2" v-text="submenu.titleId" > </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar size="26">
                    <v-img :src="submenu.icon" ></v-img>
                  </v-list-item-avatar>
                </template>

              </v-list-item>

            </v-list-group>

          </v-list>

        </v-navigation-drawer>
      </v-card>

      <v-card width="40%" v-if="false">
        <v-card-title>
          <v-flex class="d-flex">
            <v-btn
                icon
                @click.stop="mini = !mini"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>

            <div class="ml-2 blue--text font-weight-bold">
              {{ fkomoditiTypeSelected.description }}
            </div>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('cari')"
                single-line
                hide-details
                dense
            ></v-text-field>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <v-data-table
              :headers="headers"
              @click:row="handleClick"
              :items="fkomodities"
              :search="search"
          >
            <template v-slot:[`item.number`]="{ index }">
              {{index+1}}
            </template>

            <template  v-slot:[`item.alamat`]="{ item }">
              <div class="caption">
                <span>
                  {{ lookupFSubArea(item.fsubAreaBean).description }}
                </span>
                <span>
                  - {{ lookupFArea(lookupFSubArea(item.fsubAreaBean).fareaBean).description }}
                </span>
              </div>
            </template>

            <template  v-slot:[`item.nilai1`]="{ item }">
              <div class="caption">
                <span>
                  {{ item.nilai1 }}
                </span>
                <span class="grey--text">
                    {{ lookupFSatuan(lookupFKomoditiType(item.fkomoditiTypeBean).fsatuanBean).kode1 }}
                </span>
              </div>
            </template>


          </v-data-table>
        </v-card-text>
      </v-card>

      <KomoditiMainMap
          ref="refMainmap"
          :fkomodities="fkomodities"
          :itemsFKomoditiType="itemsFKomoditiType"
          style="width: 100%"
          class="ml-2 elevation-5"
          @dialogSumOfItemFromMap="requestReloadData"
      ></KomoditiMainMap>

    </v-flex>

    <v-flex>
      <PublicFooter></PublicFooter>
    </v-flex>
  </div>
</template>

<script>

import KomoditiMainMap from "./KomoditiMainMap";
import PublicFooter from "@/components/PublicFooter";
import FKomoditiTypeService from "../../services/apiservices/f-komoditi-type-service";
import FSectorTypeService from "@/services/apiservices/f-sector-type-service";
import FileService from "@/services/apiservices/file-service";
import FKomoditiService from "@/services/apiservices/f-komoditi-service";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";
import FAreaService from "@/services/apiservices/f-area-service";
import FSatuanService from "@/services/apiservices/f-satuan-service";

export default {
  name: 'KomoditiMain',
  components: { KomoditiMainMap, PublicFooter},
  data(){
    return{
      drawer: true,
      mini: false,
      fkomoditiTypeSelected: '',
      itemsFSectorType: [],
      itemsFKomoditiType: [],

      search: '',
      search2: '',
      fkomodities: [],
      itemModified: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        // { text: 'Description', value: 'description', width:'15%' },
        { text: 'Alamat', value: 'alamat', width:'30%' },
        { text: 'Produksi', value: 'nilai1', width:'20%' },
        // { text: 'Lat', value: 'lat', width:'10%' },
        // { text: 'Lon', value: 'lon', width:'10%' },
      ],
    }
  },
  watch:{
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFKomoditi()
        }
      }
    },
    tab: function (value){
      this.fetchData(this.itemsFKomoditiTypeComputed[value])
    }
  },
  computed:{
    itemsFKomoditiTypeComputed(){
      return this.itemsFKomoditiType
    },

    nav_drawers(){
      let menus = []
      this.itemsFSectorType.forEach(sector =>{
         const myMenu =
                 {
                   id: sector.id,
                   titleId: this.lang==='en'? sector.descriptionEn: sector.description,
                   icon: this.lookupImageUrlLazy(sector),
                   active: false,
                   visible: true,
                   items: this.nav_drawers_sub(sector.id)

                 }

          if (sector.statusActive===true &&
              ! sector.description.includes("Pariwisata") &&
              ! sector.description.includes("Infrastruktur") &&
              ! sector.description.includes("Properti") &&
              ! sector.description.includes("Transportasi") &&
              ! sector.description.includes("Industri Manu") &&
              ! sector.description.includes("Infrastruktur") &&
              ! sector.description.includes("Pariwisata")
          ){
            menus.push(myMenu)
          }

      })

      return menus
    },

    computedItemsFSubArea(){
      return this.$store.state.potensi.itemsFSubArea
    },
    computedItemsFArea(){
      return this.$store.state.potensi.itemsFArea
    },
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    sumOfItem:{
      get(){
        return this.$store.state.helperModule.sumOfItem
      },
      set(val) {
        this.$store.dispatch('helperModule/setSumOfItem', val)
      }
    }


  },
  methods:{
    requestReloadData(){
      this.fetchData(this.fkomoditiTypeSelected)
    },
    nav_drawers_sub(sectorTypeBean){
      // console.log(sectorTypeBean)
      let sub_menus = []
      this.itemsFKomoditiType.filter(x => x.fsectorTypeBean === sectorTypeBean).forEach( item =>{
        const newItem = {
          id:item.id, titleId: item.description, titleEn: item.descriptionEn, icon: this.lookupImageUrlLazy(item), visible:  true
        }
        if(item.statusActive===true){
          sub_menus.push(newItem)
        }
      })

      return sub_menus
    },
    submenuClick(itemId){
      // console.log(itemId)
      this.fkomoditiTypeSelected = this.lookupFKomoditiType(itemId)
      this.fetchData(this.fkomoditiTypeSelected)
    },
    fetchParent(){
      FSubAreaService.getAllFSubAreaPublic().then(
          response => {
            this.$store.dispatch("potensi/updateItemsFSubArea", response.data)
          }
      )
      FAreaService.getAllFAreaPublic().then(
          response => {
            // this.itemsFArea = response.data
            this.$store.dispatch("potensi/updateItemsFArea", response.data)

          }
      )

      FSectorTypeService.getAllFSectorTypePublic().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.itemsFSectorType = response.data
          },
          error =>{
            console.log(error)
          }
      )
      FKomoditiTypeService.getAllFKomoditiTypePublic().then(
          response => {
            this.itemsFKomoditiType = response.data
            this.$store.dispatch('potensi/updateItemsFKomoditiType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )

      FSatuanService.getAllFSatuanPublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFSatuan', response.data)
          },
          error => {
            console.log(error.response)
          }
      )

    },
    handleClick(value){
      // console.log(value)
      this.itemModified = value
      this.$refs.refMainmap.changeSelection(this.itemModified)
    },
    fetchData(item){
      // console.log(item.description)

      // FKomoditiService.getAllFKomoditiByTypePublic(item.id).then(
      FKomoditiService.getAllFKomoditiByTypePublicTerbesarJumlah(item.id, this.sumOfItem).then(
          response =>{
            // console.log(response.data)
            response.data.filter(item => item.statusActive===true).forEach( item => {
              const subArea =  this.lookupFSubArea(item.fsubAreaBean)
              if (item.lat ===0 || item.lon ===0){
                item.lat = parseFloat(subArea.lat)
                item.lon = parseFloat(subArea.lon)
              }
            })

            this.fkomodities = response.data
            this.$store.dispatch("potensi/updateItemsFKomoditi", response.data)

          },
          error =>{
            console.log(error)
          }
      )
    },

    lookupFKomoditi (fkomoditiBean) {
      const items = this.fkomodities.filter(x => x.id==fkomoditiBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },
    lookupFKomoditiType (fkomoditiTypeBean) {
      const str = this.itemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFSatuan (fsatuanBean) {
      const str = this.$store.state.potensi.itemsFSatuan.filter(x => x.id===fsatuanBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFSubArea (fsubAreaBean) {
      const items = this.computedItemsFSubArea.filter(x => x.id==fsubAreaBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },
    lookupFArea (fareaBean) {
      const items = this.computedItemsFArea.filter(x => x.id==fareaBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
  },

  mounted() {
    this.fetchParent()

  }
}
</script>

<style scoped>

</style>