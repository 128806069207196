<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
            dense
        >
          <v-btn
              icon
              dark
              @click="closeForm"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items >

          </v-toolbar-items>
        </v-toolbar>

        <v-card-title>
          <v-flex>
<!--            <v-spacer></v-spacer>-->
<!--            <v-text-field-->
<!--                v-model="search"-->
<!--                append-icon="mdi-magnify"-->
<!--                :label="$t('cari')"-->
<!--                single-line-->
<!--                hide-details-->
<!--                dense-->
<!--            ></v-text-field>-->

            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-flex>
                  <v-btn small value="10Teratas"  @click="sumOfItemClick(10)" :color="sumOfItem===10?'blue': ''">
                    <span class="caption font-weight-bold">10 Teratas</span>
                  </v-btn>
                  <v-btn  small value="25Teratas" @click="sumOfItemClick(25)" :color="sumOfItem===25?'blue': ''">
                    <span class="caption font-weight-bold">25 Teratas</span>
                  </v-btn>
                  <v-btn  small value="Semua" @click="sumOfItemClick(9999999)" :color="sumOfItem>9999998?'blue': ''">
                    <span class="caption font-weight-bold">Semua</span>
                  </v-btn>
                </v-flex>
              </v-col>
              <v-col cols="12" md="6" sm="6" class="text-right align-content-lg-stretch" >
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('cari')"
                    single-line
                    hide-details
                    dense
                ></v-text-field>

              </v-col>
            </v-row>

          </v-flex>
        </v-card-title>
        <v-card-subtitle>
          {{itemModified.district}} {{itemModified.city}}
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="fkomoditiesComputed"
              @click:row="handleClick"
              :search="search"
          >
            <template v-slot:[`item.number`]="{ index }">
              {{index+1}}
            </template>



          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>

import FileService from "../../services/apiservices/file-service";

export default {
  name: 'KomoditiTableDialog',
  components: { },
  props:{
    formMode: String
  },
  data() {
    return {
      search:'',
      title: '',

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemModified: '',
      itemsFDivision: [],
      selectedItems: '',

      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        // { text: 'Description', value: 'description', width:'15%' },
        { text: 'Alamat', value: 'alamatDesc', width:'20%' },
        { text: 'Jumlah', value: 'nilai1', width:'10%' },
      ],

    }
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    fkomoditiesComputed(){
      let komodities = []
      this.$store.state.potensi.itemsFKomoditi.forEach(x => {
        let item = x
        item.alamatDesc = `${this.lookupFSubArea(x.fsubAreaBean).description}, ${this.lookupFArea(x.fareaBean).description}`
        komodities.push(item)

        let komodityType = this.lookupFKomoditiType(item.fkomoditiTypeBean)
        /**
         * Kedobelan
         */
        // item.nilai1 += ` ${this.lookupFSatuan(this.lookupFKomoditiType(item.fkomoditiTypeBean).fsatuanBean).description}`

        if(this.lang==='id'){
          this.title = komodityType.description
        }
        if(this.lang==='en'){
          this.title = komodityType.descriptionEn
        }
      })
      return komodities
    },
    sumOfItem:{
      get(){
        return this.$store.state.helperModule.sumOfItem
      },
      set(val) {
        this.$store.dispatch('helperModule/setSumOfItem', val)
      }
    }
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFKomoditi()
        }
      }
    },
  },

  methods: {
    sumOfItemClick(value){
      this.sumOfItem = value
      this.$emit('dialogSumOfItemClick', value)
    },
    showDialog() {
      this.dialogShow = !this.dialogShow
    },
    setDialogState(value){
      this.dialogShow =value
    },
    closeForm(){
      this.dialogShow = false
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },
    lookupFKomoditiType (fkomoditiTypeBean) {
      const str = this.$store.state.potensi.itemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFSatuan (fsatuanBean) {
      const str = this.$store.state.potensi.itemsFSatuan.filter(x => x.id===fsatuanBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFSubArea (fsubAreaBean) {
      const str = this.$store.state.potensi.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFArea (fareaBean) {
      const str = this.$store.state.potensi.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    lookupImageMarkerUrl(markerImage){
      if (markerImage===undefined || markerImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        // console.log(FileService.file_url(markerImage))
        return FileService.image_url_ori(markerImage)
      }
    },
    handleClick(){
    }

  }

}
</script>

<style scoped>
.v-data-table.row-height-50 td {
  height: 10px !important;
}
</style>