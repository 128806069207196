<template>
  <div>
    <l-map
        :center="currentMarker.coordinates"
        :zoom="zoom"
        :max-zoom="maxZoom"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        style="z-index: 0; height: 800px; "
        :options="{scrollWheelZoom: false}"
    >
      <l-tile-layer
          :url="url"
          attribution="OpenStreetMap"
      ></l-tile-layer>

      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"/>

      <l-control>
        <v-btn
            @click="showTableWindow"
            color="primary"
            dark
            class="rounded-lg"
            icon
            large
            outlined
            elevation="10"
            v-if="computedMarkers.length >0"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>

      </l-control>

      <l-marker
          v-for="marker in computedMarkers "
          :key="marker.id"
          :lat-lng="marker.coordinates"
      >

        <l-tooltip class="rounded-lg">
          <div>
           {{ lookupFSubArea(lookupFKomoditi(marker.id).fsubAreaBean).description }}
          </div>
          <div>
            {{ lookupFArea(lookupFSubArea(lookupFKomoditi(marker.id).fsubAreaBean).fareaBean).description }}
          </div>
        </l-tooltip>

        <l-icon v-if="! marker.iconUrl">
          <v-icon color="blue">mdi-map-marker</v-icon>
        </l-icon>
        <l-icon v-else
                :icon-size="marker.iconSize"
                :icon-url="marker.iconUrl"
        />
        <l-popup>
          <v-flex class="d-flex" v-if="lang==='id'">
            {{ lookupFKomoditi(marker.id).description }}
          </v-flex>
          <v-flex class="d-flex" v-if="lang==='en'">
            {{ lookupFKomoditi(marker.id).descriptionEn }}
          </v-flex>

          <v-flex class="mt-1" v-if="lookupFKomoditi(marker.id).fsubAreaBean !==undefined">
            <div class="caption font-weight-light">
              {{ lookupFSubArea(lookupFKomoditi(marker.id).fsubAreaBean).description }}
            </div>
          </v-flex>
          <div>
            {{ lookupFKomoditi(marker.id).nilai1 }}
            <span class="grey--text">
              {{ lookupFSatuan(lookupFKomoditiType(lookupFKomoditi(marker.id).fkomoditiTypeBean).fsatuanBean).kode1 }}
            </span>

          </div>

          <v-flex class="mt-2 d-flex">
            <v-spacer></v-spacer>
            <v-btn @click="openGoogleMap(lookupFKomoditi(marker.id))" x-small plain>
              <v-icon small color="orange">mdi-map-marker</v-icon>
              Google
            </v-btn>
          </v-flex>

        </l-popup>

      </l-marker>

      <l-marker
          key="1"
          :lat-lng="currentMarker.coordinates"
          ref="marker"
      >
          <l-icon v-if="! currentMarker.iconUrl">
            <v-icon color="blue">mdi-map-marker</v-icon>
          </l-icon>
          <l-icon v-else
              :icon-size="currentMarker.iconSize"
              :icon-url="currentMarker.iconUrl"
          />
        <l-popup>
          <v-flex class="d-flex caption font-weight-bold" v-if="false">
            {{ lookupFKomoditiType(itemModified.fkomoditiTypeBean).description }}
          </v-flex>
          <v-flex class="d-flex">
            {{ itemModified.kode1 }}
          </v-flex>
          <v-flex class="d-flex font-weight-bold">
            {{ itemModified.description }}
          </v-flex>

          <v-flex class="mt-1" v-if="itemModified.fsubAreaBean > 0">
            <div class="caption">
              {{ lookupFSubArea(itemModified.fsubAreaBean).description }}

              <router-link :to="`/public-profil-daerah-detil/${lookupFSubArea(itemModified.fsubAreaBean).fareaBean}`" style="text-decoration: none">
                <v-btn color="blue" dark x-small rounded>
                  {{ lookupFArea(lookupFSubArea(itemModified.fsubAreaBean).fareaBean).description }}
                  <v-icon small>mdi-play</v-icon>
                </v-btn>
              </router-link>
            </div>
          </v-flex>
          <div>
            {{itemModified.nilai1}}
            <span class="grey--text">
              {{ lookupFSatuan(lookupFKomoditiType(itemModified.fkomoditiTypeBean).fsatuanBean).kode1 }}
            </span>

          </div>

          <v-flex class="mt-2 d-flex">
            <v-spacer></v-spacer>
            <v-btn @click="openGoogleMap(itemModified)" x-small plain>
              <v-icon small color="orange">mdi-map-marker</v-icon>
              Google
            </v-btn>
          </v-flex>

        </l-popup>
      </l-marker>
    </l-map>

    <KomoditiTableDialog
        ref="refKomoditiTableDialog"
        @dialogSumOfItemClick="dialogSumOfItemHandler"
    ></KomoditiTableDialog>
  </div>
</template>

<script>
import {
  LMap, LTileLayer, LMarker, LIcon,
  LPopup,
  LControlLayers, LControl, LTooltip
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import FileService from "../../services/apiservices/file-service";
import {format, parseISO} from "date-fns";
import FInfratructure from "@/models/f-komoditi";
import FKomoditiType from "@/models/f-komoditi-type";
import KomoditiTableDialog from "@/components/public_komoditi/KomoditiTableDialog";

export default {
  name: "KomoditiMainMap",
  props:{
    fkomodities:[],
    itemsFKomoditiType: [],
  },
  components: {
    KomoditiTableDialog,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LControl,
    LTooltip,
    LControlLayers
  },
  data () {
    return {

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 9,
      pageSizes: [9, 25, 50, 150, 500],

      search: '',

      showGallery: false,
      showCctv: false,

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // center: [ -2.692921, 111.634003 ],
      zoom: 9,
      maxZoom: 18,

      itemModified: new FInfratructure(),
      currentTipe: new FKomoditiType(),
      currentMarker:
          {
            id: 1,
            coordinates: [-7.4220966,112.8667491],
            // iconUrl: this.lookupImageMarkerUrl(this.currentTipe.markerImage1 ),
            iconSize: [35, 55],
          },

      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
              'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'Satelite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
      ],

      overlayAdministrasiProviders: [
        {
          name: 'Layer 1',
          visible: false,
        },
      ],

      overlayItemProviders: [
      ],


    }
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFKomoditi()
        }
      }
    },
  },
  computed:{
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    computedMarkers(){
      let markers = []

      if (this.fkomodities !==undefined){
        this.fkomodities.filter(x => x.city===this.itemModified.city ||
        this.lookupFSubArea(x.fsubAreaBean).description.trim().toUpperCase().includes(this.itemModified.city.trim().toUpperCase())).forEach( item =>{
          item.toString()

          if (item.lat !==0 && item.lon !==0){

            // const komoditiType = this.lookupFKomoditiType(item.fkomoditiTypeBean)
            // const komoditiTypeMarker1 = komoditiType.markerImage1

            const tipe = this.lookupFKomoditiType(item.fkomoditiTypeBean)
            const theIconUrl = this.lookupImageMarkerUrl(tipe.markerImage1)

            let marker = {
              id: item.id, coordinates: [item.lat, item.lon],
              iconUrl: theIconUrl,
              iconSize: [25, 25],
              // iconAnchor: [16, 37]
            }

            // console.log(marker)
            markers.push(marker)

          }
        })
      }

      return markers
    },

    computedItemsFSubArea(){
      return this.$store.state.potensi.itemsFSubArea
    },
    computedItemsFArea(){
      return this.$store.state.potensi.itemsFArea
    },

  },
  methods: {
    dialogSumOfItemHandler(){
      this.$emit('dialogSumOfItemFromMap')
    },

    changeSelection(value){
      value.toString()

      const tipe = this.lookupFKomoditiType(this.itemModified.fkomoditiTypeBean)
      const theIconUrl = this.lookupImageMarkerUrl(tipe.markerImage1)
      const newMarker =
      {
          id: 1,
          iconUrl: theIconUrl,
          coordinates: [value.lat, value.lon],
          iconSize: [45, 65],
          // iconAnchor: [16, 37]
      }

      this.$nextTick(() => {
                this.itemModified = Object.assign({}, value)
                this.currentMarker = newMarker
                this.zoom = 10
                this.$refs.marker.mapObject.openPopup()
              })

    },

    streetmap(){
      this.maxZoom =18
      this.url= 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },
    satelite(){
      this.maxZoom =17
      this.url = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
      // console.log(this.markers)
    },
    centerUpdated (center) {
      this.center = center;
    },
    markerClicked(value){
      this.retrieveFiles(value)

    },
    retrieveFiles(id){
      console.log(id)
    },
    innerClick() {
      alert("Click!");
    },
    fetchParent(){
    },

    lookupFKomoditi (fkomoditiBean) {
      const str = this.fkomodities.filter(x => x.id===fkomoditiBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFKomoditiType (fkomoditiTypeBean) {
      const str = this.$store.state.potensi.itemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFSatuan (fsatuanBean) {
      const str = this.$store.state.potensi.itemsFSatuan.filter(x => x.id===fsatuanBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    lookupFSubArea (fsubAreaBean) {
      const str = this.computedItemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFArea (fareaBean) {
      const str = this.computedItemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/pasar.png'
    },
    lookupImageMarkerUrl(markerImage){
      if (markerImage===undefined || markerImage===""){
        return './assets/images/pasar.png'
      }else {
        return FileService.image_url_ori(markerImage)
      }
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    openGoogleMap(item) {
      const urlSuffix = `${item.lat},${item.lon}&z=15`
      window.open(
          "https://maps.google.com/maps?q=" + urlSuffix,
          "_blank"
      );
    },
    showTableWindow(){
      this.$refs.refKomoditiTableDialog.showDialog()
    },

  },

  mounted() {

    const lat = this.$route.params.lat
    const lon = this.$route.params.lon
    const id = this.$route.params.id
    if (lat !==undefined && lon !==undefined){
      this.currentMarker = {id: id, coordinates: [lat,lon] }
    }else {
      this.currentMarker =  {id: 0, coordinates: [-7.4220966,112.8667491]} //tidak ditampilkan
    }

    this.$nextTick(() => {
      this.currentMarker =  {id: 0, coordinates: [-7.4220966,112.8667491]}
      // this.$refs.marker.mapObject.openPopup()
    })

  }
}
</script>

<style scoped>

</style>