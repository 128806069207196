<template>
  <KomoditiMain></KomoditiMain>
</template>

<script>
import KomoditiMain from "../../components/public_komoditi/KomoditiMain";
export default {
  name: "DaaDukunView",
  components:{
    KomoditiMain
  }
}
</script>

<style scoped>

</style>